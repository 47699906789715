import "../assets/css/Dashboard.css";

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router";
import { useTheme } from "@mui/material";

import {
  embedDashboard,
  EmbedDashboardParams,
} from "../modules/superset-embedded-sdk";

import { AxiosResponse } from "axios";
import { DashboardInfo } from "../utils/types";

import { baseUrl, supersetBaseUrl } from "../config";
import axiosConfig from "../utils/axiosConfig";

import { Box, Stack, Paper } from "@mui/material";
import ExploreDataMenu from "./ExploreDataMenu";

interface IEmbedded {
  dashboardInfo: DashboardInfo;
}

function Embedded({ dashboardInfo }: IEmbedded) {
  const theme = useTheme();

  const { dashboard_uuid, hide_chart_controls } = dashboardInfo;

  const [queryParameters] = useSearchParams();
  const nativeFilters = queryParameters.get("native_filters");

  const [iframeHeight, setIframeHeight] = useState<number>(600);

  useEffect(() => {
    if (!dashboard_uuid) return;

    const fetchGuestToken = async (_uuid: string) => {
      try {
        // Make a GET request to the Superset API to obtain the guest token
        const response: AxiosResponse = await axiosConfig({
          method: "get",
          url: `${baseUrl}/guest_token/?uuid=${_uuid}`,
        });

        // Check if the response status is OK (200)
        if (response.status === 200 && response.data.guest_token) {
          // Extract the guest token from the response data
          const token: string = response.data.guest_token;
          return token;
        } else {
          throw new Error("Failed to fetch the guest token.");
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred while fetching the guest token");
      }
    };

    const embed = async () => {
      if (document.getElementById("my-superset-container")) {
        const embedOptions: EmbedDashboardParams = {
          id: dashboard_uuid,
          supersetDomain: supersetBaseUrl!,
          mountPoint: document.getElementById("my-superset-container")!,
          fetchGuestToken: async () => fetchGuestToken(dashboard_uuid)!,
          dashboardUiConfig: {
            hideTitle: false,
            hideChartControls: hide_chart_controls,
            hideTab: false,
            filters: { expanded: false },
          },
        };
        if (nativeFilters) embedOptions.nativeFilters = nativeFilters;
        const myDashboard = await embedDashboard(embedOptions);

        setInterval(async () => {
          const { height } = await myDashboard.getScrollSize();
          setIframeHeight(height);
        }, 1000);
      }
    };
    embed();
  }, [dashboard_uuid, hide_chart_controls, nativeFilters]);

  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Box
        sx={{
          position: "absolute",
          top: theme.spacing(14),
          right: theme.spacing(4),
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          {dashboardInfo &&
            dashboardInfo.charts &&
            dashboardInfo.charts.length > 0 && (
              <ExploreDataMenu dashboardInfo={dashboardInfo} />
            )}
        </Stack>
      </Box>
      <Paper
        id="my-superset-container"
        sx={{
          height: iframeHeight + "px",
          borderRadius: "5px",
          border: "0px",
          boxShadow: "none",
          backgroundColor: "#f8f7fc",
        }}
      />
    </Box>
  );
}

export default Embedded;
