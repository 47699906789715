import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useGoogleAuth } from "../contexts/GoogleAuthContext";
import { Role, hasRequiredRole } from "../utils/roles";

interface PrivateRouteProps {
  children: any;
  roles: Role[];
}

export const PrivateRoute = ({ children, roles }: PrivateRouteProps) => {
  const { accessToken, userRoles } = useGoogleAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const performNavigation = async () => {
      if (!accessToken) {
        navigate("/login");
        return;
      }
      if (!hasRequiredRole(userRoles, roles)) {
        navigate("/403");
      }
    };

    performNavigation();
  }, [navigate, roles, accessToken, userRoles]);

  return children;
};

export default PrivateRoute;
