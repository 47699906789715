import "../assets/css/Dashboard.css";
import { Typography } from "@mui/material";

import DashboardHeader from "../components/DashboardHeader";
import Embeded from "../components/Embeded";
import CustomDashboard from "../components/CustomDashboard";

import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useData } from "../contexts/DataContext";

import { customDashboards } from "../dashboards";
import { DashboardInfo } from "../utils/types";
import { DashboardFilterProvider } from "../contexts/DashboardFilterContext";

interface IDashboardProps {
  isEmbedded?: boolean;
}

export default function Dashboard({ isEmbedded = false }: IDashboardProps) {
  const params = useParams();
  const id: number | undefined = params.id ? parseInt(params.id) : undefined;

  const { dashboardList } = useData();

  const [dashboardInfo, setDashboardInfo] = useState<DashboardInfo | null>(
    null
  );

  useEffect(() => {
    if (!id || dashboardList.length === 0) return;

    const dbInfo: DashboardInfo | undefined = dashboardList.find(
      (dashboard) => dashboard.id === id
    );

    if (!dbInfo) return;
    setDashboardInfo(dbInfo);
  }, [id, dashboardList]);

  useEffect(() => {
    if (!document.referrer) return;
    const height = document.documentElement.scrollHeight;
    const parentOrigin = new URL(document.referrer).origin;
    window.parent.postMessage({ type: "resize", height }, parentOrigin);
  }, []);

  const customDashboard =
    id && customDashboards[id] ? customDashboards[id] : null;

  return (
    <>
      {!isEmbedded && (
        <DashboardHeader
          dashboardInfo={dashboardInfo}
          hideBreadcrumbs={isEmbedded}
          hideSectionMenu={isEmbedded}
          hidePreviousYearsMenu={!customDashboard?.showPreviousYearMenu}
          hideMonthWiseToggle={!customDashboard?.showMonthWiseToggle}
        />
      )}
      {id && customDashboard ? (
        customDashboard.Component ? (
          <customDashboard.Component dashboardId={id} />
        ) : (
          <DashboardFilterProvider filters={customDashboard.filters}>
            <CustomDashboard id={id} hideTitle hideExploreData={isEmbedded} />
          </DashboardFilterProvider>
        )
      ) : dashboardInfo?.dashboard_uuid ? (
        <Embeded dashboardInfo={dashboardInfo} />
      ) : (
        <Typography></Typography>
      )}
    </>
  );
}
