import React, { useState } from "react";
import {
  Typography,
  IconButton,
  InputAdornment,
  Select,
  SelectChangeEvent,
  MenuItem,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  LocalizationProvider,
  DatePicker,
  ArrowDropDownIcon,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MonthYearPickerProps } from "../types";
import dayjs from "dayjs";

const MonthYearPicker: React.FC<MonthYearPickerProps> = ({
  selectedMonth,
  setSelectedMonth,
  selectedYear,
  setSelectedYear,
  label,
  defaultYearLabel
}) => {
  const [openYearPicker, setOpenYearPicker] = useState(false);
  const months = [
    { name: "Jan", index: 1 },
    { name: "Feb", index: 2 },
    { name: "Mar", index: 3 },
    { name: "Apr", index: 4 },
    { name: "May", index: 5 },
    { name: "Jun", index: 6 },
    { name: "Jul", index: 7 },
    { name: "Aug", index: 8 },
    { name: "Sep", index: 9 },
    { name: "Oct", index: 10 },
    { name: "Nov", index: 11 },
    { name: "Dec", index: 12 },
  ];

  const handleMonthChange = (event: SelectChangeEvent<number | string>) => {
    const value = event.target.value === "" ? null : Number(event.target.value);
    setSelectedMonth(value);
  };

  const clearMonthSelection = () => {
    setSelectedMonth(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%",
          mb: "8px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "text.primary",
          }}
        >
          {label}
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Month Picker */}
          <Box
            sx={{
              flex: 1,
              minWidth: "90px",
              maxWidth: "100px",
            }}
          >
            <Select
              value={selectedMonth ?? ""}
              onChange={handleMonthChange}
              displayEmpty
              renderValue={(selected) =>
                selected
                  ? months.find((m) => m.index === selected)?.name
                  : "Month"
              }
              sx={{
                height: "40px",
                width: "100%",
                paddingX: "8px !important",
                "& .MuiSelect-select": {
                  paddingRight: "0px !important",
                  paddingLeft: "8px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                },
                "& .MuiSelect-icon": {
                  display: selectedMonth !== null ? "none" : "block",
                },
                "& .MuiInputBase-input": {
                  color:
                    selectedMonth === null ? "rgba(0, 0, 0, 0.38)" : "#343a40",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 200,
                    overflowY: "auto",
                  },
                },
              }}
              endAdornment={
                selectedMonth !== null ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear month selection"
                      onClick={clearMonthSelection}
                      sx={{ p: 0 }}
                      size="small"
                      edge="end"
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : undefined
              }
            >
              <MenuItem value="" disabled>
                Month
              </MenuItem>
              {months.map((month) => (
                <MenuItem key={month.index} value={month.index}>
                  {month.name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* Year Picker */}
          <Box
            sx={{
              flex: 1,
              minWidth: "100px",
              maxWidth: "120px",
            }}
          >
            <DatePicker
              open={openYearPicker}
              onClose={() => setOpenYearPicker(false)}
              views={["year"]}
              value={
                selectedYear !== null ? dayjs().set("year", selectedYear) : null
              }
              onChange={(newValue) => {
                if (newValue) {
                  setSelectedYear(newValue.year());
                } else {
                  setSelectedYear(null);
                }
              }}
              slotProps={{
                field: { clearable: true },
                textField: {
                  placeholder: defaultYearLabel,
                  InputProps: {
                    endAdornment: selectedYear ? null : (
                      <ArrowDropDownIcon
                        sx={{ color: "rgba(0, 0, 0, 0.54)", pr: 0 }}
                      />
                    ),
                    readOnly: true,
                  },
                  onClick: () => setOpenYearPicker(true),
                  sx: {
                    height: "40px",
                    width: "100%",
                    "& .MuiInputBase-input": {
                      paddingLeft: "8px",
                      paddingY: "8px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    },
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default MonthYearPicker;
