import React from "react";
import { Typography, Box } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DefaultDurationProps } from "../utils/types";

const DefaultDuration: React.FC<DefaultDurationProps> = ({ duration }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="end"
      px={"10px"}
      mt="10px"
      ml={2}
      style={{
        color: "#7c7c7c",
      }}
    >
      <CalendarTodayIcon
        sx={{ width: "14px" }}
        fontSize="small"
        style={{ marginRight: "4px", color: "#7c7c7c" }}
      />
      <Typography fontSize={"12px"}>{duration}</Typography>
    </Box>
  );
};

export default DefaultDuration;
