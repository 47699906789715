//hooks
import { useMemo } from "react";
import { useTheme } from "@mui/material";

//types
import { MouseEvent, ChangeEvent } from "react";
import { TableCellProps } from "@mui/material/TableCell";
import {
  RowsPerPageOption,
  TableColumnControlGroupValue,
} from "../modules/charts/types";

//functions
import { styled } from "@mui/material/styles";

//components
import Loader from "./Loader";
import TablePaginationActions from "./TablePaginationActions";

//ui components
import { Fragment } from "react";
import { Stack, Box, Paper } from "@mui/material";
import { TablePagination } from "@mui/material";
import { TableContainer, Table } from "@mui/material";
import { TableHead, TableBody } from "@mui/material";
import { TableRow, TableCell } from "@mui/material";

const rowsPerPageOptions: RowsPerPageOption[] = [5, 10, 50, 100];

type StyledTableCellProps = TableCellProps & {
  textalign?: "left" | "center" | "right";
};

const StyledTableCell = styled(TableCell)<StyledTableCellProps>(
  ({ theme, textalign = "left" }) => ({
    border: `0.5px solid #ECECEC`,
    fontSize: "16px",
    fontWeight: 500,
    color: "#343A40",
    textAlign: textalign,
    minWidth: "120px",
  })
);

const StyledTableHeadCell = styled(TableCell)<StyledTableCellProps>(
  ({ theme, textalign = "center" }) => ({
    borderRight: `0.5px solid ${theme.palette.divider}`,
    fontSize: "16px",
    fontWeight: 500,
    color: "#343A40",
    backgroundColor: "#ECEFF3",
    textAlign: textalign,
    // minWidth: "120px",
  })
);

interface TablePaginationProps {
  page: number;
  rowsPerPage: number;
  totalPages: number;
  handleChangePage: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

interface ICustomTable {
  rows?: any[] | null;
  columns: TableColumnControlGroupValue[];
  loading: boolean;
  paginationProps?: TablePaginationProps;
}

export default function CustomTable({
  rows,
  columns,
  loading,
  paginationProps,
}: ICustomTable) {
  const theme = useTheme();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    paginationProps && paginationProps.page > 0
      ? Math.max(
          0,
          (1 + paginationProps.page) * paginationProps.rowsPerPage -
            paginationProps.rowsPerPage
        )
      : 0;

  const data = useMemo<any[]>(() => {
    if (!rows) return [];

    //process calculated fields
    rows.forEach((row, idx, _rows) => {
      columns.forEach(({ column, calculate }) => {
        if (!calculate) return;
        _rows[idx][column] = calculate(row);
      });
    });

    rows.forEach((row, idx, _rows) => {
      const _row = row;

      columns.forEach(({ column, parentColumns }) => {
        if ("ignoreCols" in _row && _row.ignoreCols.includes(column)) return;

        let nextIdx = idx < _rows.length - 1 ? idx + 1 : null;
        let span = 1;

        function hasSameParentValues() {
          if (!parentColumns) return false;
          if (parentColumns.length === 0) return true;
          return parentColumns.every(
            (parentField: string) =>
              nextIdx && _row[parentField] === _rows[nextIdx][parentField]
          );
        }

        try {
          while (
            nextIdx &&
            _row[column] === _rows[nextIdx][column] &&
            hasSameParentValues()
          ) {
            if (!("rowSpan" in _row)) _row.rowSpan = {};
            if (!(column in _row.rowSpan)) _row.rowSpan[column] = 1;
            span = nextIdx - idx + 1;
            _row.rowSpan[column] = span;

            if (!("ignoreCols" in _rows[nextIdx]))
              _rows[nextIdx].ignoreCols = [];
            _rows[nextIdx].ignoreCols.push(column);

            nextIdx = nextIdx < _rows.length - 1 ? nextIdx + 1 : null;
          }
        } catch (e) {
          console.error(e);
        }
      });

      _rows[idx] = _row;
    });
    return rows;
  }, [rows, columns]);

  return (
    <Stack direction="column" sx={{ width: "100%", height: `100%` }}>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          width: `calc(100% - ${theme.spacing(4)})`,
          maxHeight: `calc(100% - ${theme.spacing(17)})`,
          boxShadow: "none",
          borderRadius: "5px",
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Table className={"table"} aria-label="project table" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(
                (column, idx) =>
                  !column.hidden && (
                    <StyledTableHeadCell
                      key={idx}
                      sx={
                        column.getHeadStyles
                          ? column.getHeadStyles(theme)
                          : { minWidth: column.width }
                      }
                    >
                      {column.label}
                    </StyledTableHeadCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any, rowIdx) => (
              <TableRow key={rowIdx}>
                {columns.map(
                  ({ column, hidden, getCellStyles, formatValue }, colIdx) =>
                    "ignoreCols" in row && row.ignoreCols.includes(column) ? (
                      <Fragment key={colIdx}></Fragment>
                    ) : (
                      !hidden && (
                        <StyledTableCell
                          key={colIdx}
                          textalign="center"
                          rowSpan={
                            row.rowSpan && column in row.rowSpan
                              ? row.rowSpan[column]
                              : 1
                          }
                          sx={
                            getCellStyles
                              ? getCellStyles(row, row[column], theme)
                              : {}
                          }
                        >
                          {formatValue
                            ? formatValue(row[column])
                            : row[column] || ""}
                        </StyledTableCell>
                      )
                    )
                )}
              </TableRow>
            ))}
            {rows && rows.length === 0 && (
              <TableRow>
                <StyledTableCell colSpan={columns.length} textalign="center">
                  {"No results to display"}
                </StyledTableCell>
              </TableRow>
            )}
            {paginationProps && emptyRows > 0 && (
              <TableRow>
                <StyledTableCell colSpan={columns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <Loader />}
      {paginationProps && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={3}
          count={paginationProps.totalPages}
          rowsPerPage={paginationProps.rowsPerPage}
          page={paginationProps.page}
          slotProps={{
            select: {
              inputProps: {
                "aria-label": "rows per page",
              },
              native: false,
            },
          }}
          onPageChange={paginationProps.handleChangePage}
          onRowsPerPageChange={paginationProps.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          component={Box}
          sx={{
            position: "absolute",
            bottom: theme.spacing(2),
            right: theme.spacing(2),
          }}
        />
      )}
      <Box height={theme.spacing(8)}></Box>
    </Stack>
  );
}
