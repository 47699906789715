import {
    Box,
    Button,
    Divider,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
  } from "@mui/material";
  import IosShareIcon from "@mui/icons-material/IosShare";
  import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
  import { MouseEvent, useState } from "react";
  
  interface ExportOption {
    label: string;
    onClick: () => void;
  }
  
  interface ExportButtonProps {
    exportOptions: ExportOption[];
  }
  
  export const ExportButton = ({ exportOptions }: ExportButtonProps) => {
    const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
      null
    );
  
    const handleExportMenuOpen = (event: MouseEvent<HTMLElement>) => {
      setAnchorElExport(event.currentTarget);
    };
  
    const handleExportMenuClose = () => {
      setAnchorElExport(null);
    };
  
    return (
      <Box sx={{ ml: 4 }}>
        <Button
          variant="contained"
          onClick={handleExportMenuOpen}
          endIcon={<KeyboardArrowDownRoundedIcon />}
          color="primary"
        >
          Export
        </Button>
  
        <Menu
          anchorEl={anchorElExport}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          id={"charts-list"}
          keepMounted
          disableScrollLock
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElExport)}
          onClose={handleExportMenuClose}
          sx={{
            borderRadius: "4px",
            ".MuiPaper-root": {
              borderRadius: "4px",
            },
            ".MuiMenu-list": {
              p: 0,
            },
          }}
        >
          {exportOptions.map((option, index) => (
            <MenuItem
              key={option.label}
              onClick={() => {
                option.onClick();
                handleExportMenuClose();
              }}
              sx={{ py: 0 }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width={100}
                sx={{
                  py: 0,
                  height: "44px",
                  width: "100%",
                  minWidth: "120px",
                }}
              >
                <ListItemText sx={{ fontSize: "14px" }}>
                  {option.label}
                </ListItemText>
                <IosShareIcon sx={{ ml: 2, fontSize: "14px" }} />
              </Stack>
              {index < exportOptions.length - 1 && <Divider />}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };
  