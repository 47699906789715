import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Button,
  Typography,
  useTheme,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  SelectChangeEvent,
  Pagination,
} from "@mui/material";
import { AxiosRequestConfig } from "axios";
import { baseUrl } from "../../config";
import useFetch from "../../hooks/useFetch";
import Loading from "../Loading";
import {
  AccountManaerList,
  CardData,
  CustomerList,
  Data,
  ProjectTableProps,
  SummaryData,
} from "./types";
import {
  StickyTableCell,
  StickyTableHeadCell,
  StyledSecondRowTableHeadCell,
  StyledTableCell,
  StyledTableHeadCell,
  StyledInnerTableRow,
} from "./components/TableComponents";
import { KeyboardTab } from "@mui/icons-material";
import ExpandIcon from "../../assets/img/more.svg";
import LessIcon from "../../assets/img/less.svg";
import axiosConfig from "../../utils/axiosConfig";
import { useApp } from "../../contexts/AppContext";
import OverlayComponent from "./OverlayComponent";
import { MultiSelectDropdown } from "../FilterDropdowns";
import { columnNameKeys, reportType } from "./constants";
import style from "./styles/financialAnalysis";
import { scrollbarStyle } from "../../styles";
import ActiveFilterSection from "./components/ActiveFiterSection";
import dayjs, { Dayjs } from "dayjs";
import CustomerWiseTable from "./components/CustomerWiseTable";
import { DashboardInfo } from "../../utils/types";
import { useData } from "../../contexts/DataContext";
import ExploreDataMenu from "../ExploreDataMenu";
import { isEmbeddedDashboard } from "../../utils/helpers";
import MonthYearFilter from "./components/MonthYearFilter";
import {
  generateMonthsArray,
  getFormattedTimestamp,
  MIME_TYPES,
} from "../../utils/common";
import {
  convertMonthToComparable,
  getOverlayData,
  shouldRenderActiveFilterSection,
} from "./utils";
import { ExportButton } from "../ExportButton";

let expandedMonth = "";

export const FinancialAnalysisDashboard: React.FC<ProjectTableProps> = ({
  dashboardId,
}) => {
  const theme = useTheme();
  const { selectedFilter, setSelectedFilter, monthToggle } = useApp();
  const [error, setError] = useState("");
  const [yearError, setYearError] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [expandedMonths, setExpandedMonths] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState<CardData>({
    customerId: 0,
    isOpen: false,
    key: 0,
  });
  const [customerOptions, setCustomerOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [accountManagerOptions, setAccountManagerOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [selectedCustomerLabels, setSelectedCustomerLabels] = useState<
    string[]
  >([]);
  const [selectedAccountManagers, setSelectedAccountManagers] = useState<
    string[]
  >([]);
  const [selectedAccountManagersLabels, setSelectedAccountManagersLabels] =
    useState<string[]>([]);
  const [currentYear, setCurrentYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const [appliedFilter, setAppliedFilter] = useState<{
    customer: string[];
    accountManager: string[];
    report_type_project?: string;
    report_type_customer?: string;
    fromMonth?: string;
    fromYear?: string;
    toMonth?: string;
    toYear?: string;
  }>({
    customer: [],
    accountManager: [],
    report_type_project: "",
    report_type_customer: "",
    fromMonth: "",
    fromYear: (new Date().getFullYear() - 1).toString(),
    toMonth: "",
    toYear: new Date().getFullYear().toString(),
  });
  const [filterSummary, setFilterSummary] = useState<{
    Customer: string[];
    Account_Manager: string[];
    Date_Range: string;
    report_type_project?: string;
    report_type_customer?: string;
  }>({
    Customer: [],
    Account_Manager: [],
    Date_Range: "",
    report_type_project: "",
    report_type_customer: "",
  });
  const [expandedData, setExpandedData] = useState<Record<string, any>>({});
  const [currentPage, setCurrentPage] = useState<Record<string, number>>({});
  const [rowsPerPage, setRowsPerPage] = useState<Record<string, number>>({
    January: 50,
    February: 50,
    March: 50,
    April: 50,
    May: 50,
    June: 50,
    July: 50,
    August: 50,
    September: 50,
    October: 50,
    November: 50,
    December: 50,
  });
  const [totalCount, setTotalCount] = useState<Record<string, number>>({});
  const [cardData, setCardData] = useState<{ title: string; number: number }[]>(
    []
  );
  const [disabledMonths, setDisabledMonths] = useState<string[]>([]);
  const monthRefs = useRef<{ [key: string]: HTMLTableRowElement | null }>({});
  const containerRef = useRef<HTMLDivElement | null>(null);
  const currentExpandedMonth = useRef<HTMLTableRowElement | null>(null);
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs().subtract(2, "month")
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

  const [dashboardInfo, setDashboardInfo] = useState<DashboardInfo | null>(
    null
  );
  const [hideSummary, setHideSummary] = useState(false);

  const { dashboardList } = useData();
  const [selectedFromMonth, setSelectedFromMonth] = useState<number | null>(
    null
  );
  const [selectedFromYear, setSelectedFromYear] = useState<number | null>(null);

  const [selectedToMonth, setSelectedToMonth] = useState<number | null>(null);
  const [selectedToYear, setSelectedToYear] = useState<number | null>(null);
  const [years, setYears] = useState<string[]>([
    new Date().getFullYear().toString(),
  ]);

  useEffect(() => {
    if (!dashboardId || dashboardList.length === 0) return;

    const dbInfo: DashboardInfo | undefined = dashboardList.find(
      (dashboard) => dashboard.id === dashboardId
    );

    if (!dbInfo) return;
    setDashboardInfo(dbInfo);
  }, [dashboardId, dashboardList]);

  const defaultFromYear = (new Date().getFullYear() - 1).toString();
  const defaultToYear = new Date().getFullYear().toString();

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded);
  };

  const months = generateMonthsArray();

  useEffect(() => {
    if (toDate && fromDate) {
      applyFilter(true);
    }
  }, []);

  useEffect(() => {
    const bothYearsSelected = selectedFromYear && selectedToYear;
    const noYearsSelected = !selectedFromYear && !selectedToYear;
    const hasOtherFilters =
      selectedAccountManagers.length !== 0 ||
      selectedCustomers.length !== 0 ||
      selectedFromMonth ||
      selectedToMonth;

    const noFiltersSelected = noYearsSelected && !hasOtherFilters;

    if (noFiltersSelected) {
      setYearError("");
      setEnableButton(false);
    } else if (bothYearsSelected) {
      const fromDate = new Date(selectedFromYear, selectedFromMonth ?? 0);
      const toDate = new Date(selectedToYear, selectedToMonth ?? 11);

      if (fromDate > toDate) {
        setYearError("Valid date range required");
        setEnableButton(false);
      } else {
        setYearError("");
        setEnableButton(true);
      }
    } else if (hasOtherFilters && noYearsSelected) {
      setYearError("");
      setEnableButton(true);
    } else {
      setYearError("Both 'From' and 'To' years are required.");
      setEnableButton(false);
    }
  }, [
    selectedFromYear,
    selectedToYear,
    selectedFromMonth,
    selectedToMonth,
    selectedAccountManagers,
    selectedCustomers,
  ]);

  const handleCellClick = async (
    month: string,
    filterChange?: boolean,
    rows?: number,
    newPage?: number,
    pageChange?: boolean
  ) => {
    if (isInvalidCell(month)) return;

    if (isAlreadyExpandedMonth(month, filterChange, pageChange)) {
      collapseMonth(month);
      return;
    }

    expandMonth(month);
    setLoading(true);

    try {
      const url = generateDrillDownUrl(
        month,
        filterChange,
        rows,
        newPage,
        pageChange
      );
      const response = await fetchDrillDownData(url);

      if (response?.status === 200 && response?.data) {
        updateExpandedData(month, response.data, newPage);
      } else {
        throw new Error("Failed to fetch the data.");
      }
    } catch (error) {
      console.error(error);
      throw new Error("An error occurred while fetching the data.");
    } finally {
      setLoading(false);
    }
  };

  const isInvalidCell = (month: string) =>
    disabledMonths.includes(month) || ![25, 30].includes(dashboardId);

  const isAlreadyExpandedMonth = (
    month: string,
    filterChange?: boolean,
    pageChange?: boolean
  ) => expandedMonths.includes(month) && !filterChange && !pageChange;

  const collapseMonth = (month: string) => {
    setExpandedMonths(expandedMonths.filter((m) => m !== month));
  };

  const expandMonth = (month: string) => {
    expandedMonth = month;
    setExpandedMonths([month]);
  };

  const generateDrillDownUrl = (
    month: string,
    filterChange?: boolean,
    rows?: number,
    newPage?: number,
    pageChange?: boolean
  ) => {
    const basePath =
      dashboardId === 25
        ? "financial-overview"
        : "financial-overview-grade-average";

    const isOverview =
      selectedFilter.selectedLevel1?.label === reportType.overview ||
      (!selectedFilter.selectedLevel1 && !selectedFilter.selectedLevel2);

    const baseParams = `dashboard_id=${dashboardId}&month=${convertMonthToComparable(
      month
    )}&page_size=${filterChange ? rows : rowsPerPage[month]}&from_year=${
      appliedFilter.fromYear
    }&from_month=${appliedFilter.fromMonth}&to_year=${
      appliedFilter.toYear
    }&to_month=${appliedFilter.toMonth}&page=${pageChange ? newPage : 1}`;

    return isOverview
      ? `${baseUrl}/${basePath}/customer?${baseParams}&customer_id=${
          appliedFilter.customer || ""
        }&account_manager_id=${appliedFilter.accountManager || ""}`
      : `${baseUrl}/${basePath}/by-report-type/${selectedFilter.selectedLevel1?.label.toLocaleLowerCase()}?${baseParams}&entity_id=${
          selectedFilter.selectedLevel2?.value ?? ""
        }`;
  };

  const fetchDrillDownData = async (url: string) => {
    return await axiosConfig({ method: "get", url });
  };

  const updateExpandedData = (month: string, data: any, newPage?: number) => {
    const { data: expandedData, count } = data;
    setExpandedData((prevData) => ({ ...prevData, [month]: expandedData }));
    setCurrentPage((prevPage) => ({ ...prevPage, [month]: newPage ?? 1 }));
    setTotalCount((prevCount) => ({ ...prevCount, [month]: count }));
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
    month: string
  ) => {
    handleCellClick(month, false, rowsPerPage[month], newPage, true);
  };

  const handleRowsPerPageChange = (
    event: SelectChangeEvent<number>,
    month: string
  ) => {
    setRowsPerPage((prevRowsPerPage) => ({
      ...prevRowsPerPage,
      [month]: Number(event.target.value),
    }));
    setCurrentPage((prevPage) => ({
      ...prevPage,
      [month]: 1,
    }));
    handleCellClick(month, true, Number(event.target.value), 1, false);
  };

  const applyFilter = (apply: boolean) => {
    const hasChanged = (a: any, b: any) =>
      JSON.stringify(a) !== JSON.stringify(b);

    setEnableButton(false);

    const newAppliedFilter = {
      customer: selectedCustomers || [],
      accountManager: selectedAccountManagers || [],
      fromMonth: selectedFromMonth?.toString() ?? "",
      fromYear: selectedFromYear?.toString() ?? defaultFromYear,
      toMonth: selectedToMonth?.toString() ?? "",
      toYear: selectedToYear?.toString() ?? defaultToYear,
    };

    setAppliedFilter(newAppliedFilter);

    if (apply) {
      const defaultYear = new Date().getFullYear();
      setFilterSummary({
        Customer: selectedCustomerLabels || [],
        Account_Manager: selectedAccountManagersLabels || [],
        Date_Range: `${selectedFromMonth ?? "NA"}/${
          selectedFromYear ?? defaultYear - 1
        } - ${selectedToMonth ?? "NA"}/${selectedToYear ?? defaultYear}`,
      });
    }

    if (
      hasChanged(appliedFilter.customer, selectedCustomers) ||
      hasChanged(appliedFilter.accountManager, selectedAccountManagers)
    ) {
      setSelectedFilter({ selectedLevel1: null, selectedLevel2: null });
    }
  };

  const clearFilter = (filterKey: string) => {
    switch (filterKey) {
      case "Customer":
        setSelectedCustomers([]);
        setAppliedFilter((prev) => ({ ...prev, customer: [] }));
        setFilterSummary((prev) => ({ ...prev, Customer: [] }));
        setSelectedCustomerLabels([]);
        setSelectedFilter({ selectedLevel1: null, selectedLevel2: null });
        break;
      case "Account_Manager":
        setSelectedAccountManagers([]);
        setSelectedAccountManagersLabels([]);
        setAppliedFilter((prev) => ({ ...prev, accountManager: [] }));
        setFilterSummary((prev) => ({ ...prev, Account_Manager: [] }));
        break;
      case "Date_Range":
        setSelectedFromMonth(null);
        setSelectedToMonth(null);
        setSelectedFromYear(null);
        setSelectedToYear(null);
        setAppliedFilter((prev) => ({
          ...prev,
          fromMonth: "",
          fromYear: defaultFromYear,
          toMonth: "",
          toYear: defaultToYear,
        }));
        setFilterSummary((prev) => ({
          ...prev,
          Date_Range: `${"NA"}/${defaultFromYear} - ${"NA"}/${defaultToYear}`,
        }));
        break;
      default:
        break;
    }
  };

  const clearAllFilters = (apply: boolean) => {
    setSelectedAccountManagers([]);
    setSelectedCustomerLabels([]);
    setSelectedAccountManagersLabels([]);
    setSelectedCustomers([]);
    setEnableButton(true);
    setFromDate(null);
    setToDate(null);
    setSelectedFilter({ selectedLevel1: null, selectedLevel2: null });
    setSelectedFromMonth(null);
    setSelectedFromYear(null);
    setSelectedToMonth(null);
    setSelectedToYear(null);

    if (apply) {
      setAppliedFilter({
        customer: [],
        accountManager: [],
        report_type_project: "",
        report_type_customer: "",
        fromMonth: "",
        fromYear: defaultFromYear,
        toMonth: "",
        toYear: defaultToYear,
      });
      setFilterSummary({
        Customer: [],
        Account_Manager: [],
        Date_Range: `${"NA"}/${defaultFromYear} - ${"NA"}/${defaultToYear}`,
        report_type_project: "",
        report_type_customer: "",
      });
      setEnableButton(false);
    }
  };

  const handleCustomerChange = (values: string[], labels: string[]) => {
    setEnableButton(true);
    setSelectedCustomers(values);
    setSelectedCustomerLabels(labels);
  };

  const handleAccountManagerChange = (
    selectedValues: string[],
    labels: string[]
  ) => {
    setSelectedAccountManagers(selectedValues);
    setSelectedAccountManagersLabels(labels);
    setEnableButton(true);
  };

  const closeModal = (timer: number = 0) => {
    setTimeout(
      () =>
        setModalData((prevState) => ({
          ...prevState,
          isOpen: false,
        })),
      timer
    );
  };

  const fetchGrossProfitData = React.useMemo<AxiosRequestConfig | null>(() => {
    if (!monthToggle || ![25, 30].includes(dashboardId)) {
      return null;
    }

    setExpandedMonths([]);
    setExpandedData([]);

    const basePath =
      dashboardId === 25
        ? "financial-overview"
        : "financial-overview-grade-average";

    const baseParams = `dashboard_id=${dashboardId}&from_year=${
      appliedFilter.fromYear ?? defaultFromYear
    }&from_month=${appliedFilter.fromMonth ?? ""}&to_year=${
      appliedFilter.toYear ?? defaultToYear
    }&to_month=${appliedFilter.toMonth ?? ""}`;

    const customerParams = `&customer_id=${
      appliedFilter.customer || ""
    }&account_manager_id=${appliedFilter.accountManager || ""}`;

    if (
      !selectedFilter ||
      !selectedFilter.selectedLevel1?.label ||
      selectedFilter.selectedLevel1.label === reportType.overview
    ) {
      return {
        method: "GET",
        url: `${baseUrl}/${basePath}?${baseParams}${customerParams}`,
      };
    }

    const entityParams = `&entity_id=${
      selectedFilter.selectedLevel2?.value ?? ""
    }`;

    return {
      method: "GET",
      url: `${baseUrl}/${basePath}/summary/by-report-type/${selectedFilter.selectedLevel1.label.toLocaleLowerCase()}?${baseParams}${entityParams}`,
    };
  }, [appliedFilter, selectedFilter, monthToggle]);

  const fetchFinancialOverviewObj = useFetch<{
    data: Data;
    message: string;
  } | null>(fetchGrossProfitData);

  const fetchCurrentYearSummary =
    React.useMemo<AxiosRequestConfig | null>(() => {
      setExpandedMonths([]);
      setExpandedData([]);

      if (![25, 30].includes(dashboardId)) return null;

      const baseEndpoint =
        dashboardId === 25
          ? "financial-overview"
          : "financial-overview-grade-average";

      let url = `${baseUrl}/${baseEndpoint}/summary?dashboard_id=${dashboardId}&customer_id=${
        appliedFilter.customer || ""
      }&account_manager_id=${appliedFilter.accountManager || ""}&from_year=${
        appliedFilter.fromYear
      }&from_month=${appliedFilter.fromMonth}&to_year=${
        appliedFilter.toYear
      }&to_month=${appliedFilter.toMonth}`;

      if (
        (selectedFilter.selectedLevel1 || selectedFilter.selectedLevel2) &&
        selectedFilter.selectedLevel1?.label !== reportType.overview
      ) {
        const customerId =
          selectedFilter.selectedLevel1?.label === reportType.customer
            ? selectedFilter.selectedLevel2?.value
            : "";
        const projectId =
          selectedFilter.selectedLevel1?.label === reportType.project
            ? selectedFilter.selectedLevel2?.value
            : "";

        url = `${baseUrl}/${baseEndpoint}/summary?dashboard_id=${dashboardId}&customer_id=${customerId}&project_id=${projectId}&from_year=${
          appliedFilter.fromYear ?? defaultFromYear
        }&from_month=${appliedFilter.fromMonth}&to_year=${
          appliedFilter.toYear ?? defaultToYear
        }&to_month=${
          appliedFilter.toMonth
        }&report_type=${selectedFilter.selectedLevel1?.label.toLocaleLowerCase()}`;
      }
      return {
        method: "GET",
        url,
      };
    }, [dashboardId, appliedFilter, monthToggle]);

  const fetchCurrentYearSummaryObj = useFetch<{
    data: SummaryData;
    message: string;
  } | null>(fetchCurrentYearSummary);

  const scrollToMonth = (month: string) => {
    if (monthRefs.current[month] && containerRef.current) {
      const rowElement = monthRefs.current[month] as HTMLTableRowElement | null;
      if (rowElement) {
        const headerOffset = 74;
        const container = containerRef.current;
        const offsetTop = rowElement.offsetTop;
        const maxScrollTop = container.scrollHeight - container.clientHeight;
        let newScrollTop = offsetTop - headerOffset;
        if (newScrollTop > maxScrollTop) {
          newScrollTop = maxScrollTop;
        }
        container.scrollTo({
          top: newScrollTop,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (fetchFinancialOverviewObj?.error) {
      setError("Something went wrong!");
    } else {
      setError("");
    }
  }, [fetchFinancialOverviewObj]);

  useEffect(() => {
    if (fetchCurrentYearSummaryObj.data) {
      const obj = fetchCurrentYearSummaryObj.data?.data || {};
      const calculatedTotals = calculateTotals(obj);
      setCardData(calculatedTotals);
    }
  }, [fetchCurrentYearSummaryObj.data]);

  useEffect(() => {
    setExpandedMonths([]);
    setHideSummary(false);
    setSelectedFilter({ selectedLevel1: null, selectedLevel2: null });
  }, [monthToggle]);

  useEffect(() => {
    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const fromYear = appliedFilter.fromYear
        ? Number(appliedFilter.fromYear)
        : currentYear - 1;
      const toYear = appliedFilter.toYear
        ? Number(appliedFilter.toYear)
        : currentYear;

      const years = [];
      for (let year = toYear; year >= fromYear; year--) {
        years.push(String(year));
      }
      return years;
    };

    const years = generateYears();
    setYears(years);
    setCurrentYear(years[0]);
  }, [appliedFilter.fromYear, appliedFilter.toYear]);

  const fetchCustomerOptions = React.useMemo<AxiosRequestConfig | null>(() => {
    return {
      method: "GET",
      url: `${baseUrl}/customers?dashboard_id=${dashboardId}`,
    };
  }, [dashboardId]);

  const fetchCustomerObj = useFetch<CustomerList | null>(fetchCustomerOptions);

  useEffect(() => {
    if (fetchCustomerObj?.data?.data) {
      const options = fetchCustomerObj.data.data.map((customer) => ({
        value: customer.customer_id.toString(),
        label: customer.customer_name,
      }));
      setCustomerOptions(options);
    }
  }, [fetchCustomerObj?.data]);

  const fetchAccountManagerOptions =
    React.useMemo<AxiosRequestConfig | null>(() => {
      return {
        method: "GET",
        url: `${baseUrl}/account-manager?dashboard_id=${dashboardId}`,
      };
    }, [dashboardId]);

  const fetchAccountManagerObj = useFetch<{ data: AccountManaerList }>(
    fetchAccountManagerOptions
  );

  useEffect(() => {
    if (fetchAccountManagerObj?.data) {
      const options = fetchAccountManagerObj.data.data.map((manager) => ({
        value: manager.account_manager_id.toString(),
        label: manager.name,
      }));
      setAccountManagerOptions(options);
    }
  }, [fetchAccountManagerObj?.data]);

  useEffect(() => {
    const disabledMonths: Set<string> = new Set(months);

    if (data) {
      for (const [_, monthsIncluded] of Object.entries(data)) {
        for (const month of Object.keys(monthsIncluded || {})) {
          if (disabledMonths.has(month)) {
            disabledMonths.delete(month);
          }
        }
      }
    }

    const disabledMonthsArray = Array.from(disabledMonths);
    setDisabledMonths(disabledMonthsArray);
  }, [fetchFinancialOverviewObj?.data?.data]);

  const data: Data = fetchFinancialOverviewObj?.data?.data;

  useEffect(() => {
    const { selectedLevel1, selectedLevel2 } = selectedFilter;
    const isOverview = selectedLevel1?.label === reportType.overview;
    const isCustomer = selectedLevel1?.label === reportType.customer;
    const isProject = selectedLevel1?.label === reportType.project;

    const resetFilters = () => {
      setSelectedCustomers([]);
      setSelectedCustomerLabels([]);
      setSelectedAccountManagers([]);
      setSelectedAccountManagersLabels([]);
      setAppliedFilter({
        report_type_customer: "",
        report_type_project: "",
        accountManager: [],
        customer: [],
      });
    };

    const applyFilters = () => {
      const selectedCustomer = isCustomer ? [selectedLevel2?.value ?? ""] : [];
      const selectedCustomerLabel = isCustomer
        ? [selectedLevel2?.label ?? ""]
        : [];

      setSelectedCustomers(selectedCustomer);
      setSelectedCustomerLabels(selectedCustomerLabel);
      setSelectedAccountManagers([]);

      setAppliedFilter({
        report_type_customer: isCustomer ? selectedLevel2?.value ?? "" : "",
        report_type_project: isProject ? selectedLevel2?.value ?? "" : "",
        accountManager: [],
        customer: selectedCustomer,
        fromMonth: selectedFromMonth?.toString() ?? "",
        fromYear: selectedFromYear?.toString() ?? defaultFromYear,
        toMonth: selectedToMonth?.toString() ?? "",
        toYear: selectedToYear?.toString() ?? defaultToYear,
      });
    };

    if (selectedLevel1 || selectedLevel2) {
      isOverview ? resetFilters() : applyFilters();
    }
  }, [selectedFilter]);

  useEffect(() => {
    setFilterSummary((prev) => ({ ...prev, Customer: selectedCustomerLabels }));
  }, [appliedFilter]);

  const openOverlay = (
    year: string,
    detail: any,
    month: string,
    columnNameKey: string
  ) => {
    const { canOpenOverlay } = getOverlayData();
    if (!canOpenOverlay) return;
    if (detail.id !== modalData.customerId) closeModal();
    const cardData = {
      year,
      customerId: detail.id,
      monthIndex: "" + (months.indexOf(month) + 1),
      month,
      customer: detail.name,
      isOpen: true,
      columnNameKey,
      key: modalData.key + 1,
    };
    setTimeout(
      () =>
        setModalData((prevState) => ({
          ...prevState,
          ...cardData,
        })),
      100
    );
  };

  const getSubHeading = () => {
    switch (selectedFilter.selectedLevel1?.label) {
      case reportType.overview:
        return "Customers";
      case reportType.customer:
        return "Projects";
      case reportType.project:
        return "Employees";
      default:
        return "Customers";
    }
  };

  const getAccordionMaxHeight = () => {
    return `calc(100vh - ${expandedMonths.length ? "76px" : "206px"})`;
  };

  useEffect(() => {
    if (expandedData[expandedMonth]) {
      scrollToMonth(expandedMonth);
    }
  }, [expandedData]);

  const handleClickOutsideModal = (event: any) => {
    if (!event.target.closest(".open-overlay")) {
      closeModal();
    }
  };

  React.useEffect(() => {
    if (modalData.isOpen) {
      document.addEventListener("click", handleClickOutsideModal);
    } else {
      document.removeEventListener("click", handleClickOutsideModal);
    }
    return () => {
      document.removeEventListener("click", handleClickOutsideModal);
    };
  }, [modalData.isOpen]);

  const handleScroll = () => {
    if (containerRef.current) {
      const containerTop = containerRef.current.getBoundingClientRect().top;

      let lastStickyRow: HTMLTableRowElement | null = null;
      expandedMonths.forEach((month) => {
        const row = monthRefs.current[month];
        if (row) {
          const expandedMonthBottom =
            currentExpandedMonth.current?.getBoundingClientRect().bottom;
          if (expandedMonthBottom && expandedMonthBottom > containerTop + 108) {
            lastStickyRow = row;
          }
        }
      });

      Object.keys(monthRefs.current).forEach((month) => {
        const row = monthRefs.current[month];
        if (row) {
          const isSticky = row === lastStickyRow;
          row.style.position = isSticky ? "sticky" : "static";
          row.style.top = isSticky ? "64px" : "auto";
          row.style.zIndex = isSticky ? "2" : "auto";
          row.style.backgroundColor = isSticky ? "white" : "auto";
        }
      });
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [months]);

  const keyboardTabIcon = isExpanded ? (
    <KeyboardTab sx={{ transform: "rotate(180deg)" }} />
  ) : (
    <KeyboardTab color="primary" />
  );

  const boxStyles = {
    width: isExpanded ? "12vw" : "0.6vw",
    pb: "10px",
    mb: "10px",
    borderRadius: "5px",
  };

  const hasFiltersSelected =
    selectedAccountManagers.length > 0 ||
    selectedCustomers.length > 0 ||
    selectedFromYear ||
    selectedToYear ||
    selectedFromMonth ||
    selectedToMonth;

  const handleExport = (exportType: "excel" | "csv") => {
    const baseEndpoint =
      dashboardId === 25
        ? "financial-overview"
        : "financial-overview-grade-average";
    document.body.style.cursor = "wait";
    const entity_id =
      !selectedFilter.selectedLevel1?.label ||
      selectedFilter.selectedLevel1?.label === "overview"
        ? appliedFilter.customer
        : [selectedFilter.selectedLevel2?.value];

    const requestBody = {
      report_type:
        selectedFilter.selectedLevel1?.label.toLocaleLowerCase() ?? "overview",
      file_name: "export",
      file_type: exportType,
      filters: {
        from_year: Number(appliedFilter.fromYear) ?? defaultFromYear,
        from_month: Number(appliedFilter.fromMonth) ?? 1,
        to_month: Number(appliedFilter.toMonth) ?? 12,
        to_year: Number(appliedFilter.toYear) ?? defaultToYear,
        account_manager: appliedFilter.accountManager,
        entity_id: entity_id,
      },
    };

    axiosConfig({
      method: "POST",
      url: `${baseUrl}/${baseEndpoint}/export?dashboard_id=${dashboardId}`,
      data: requestBody,
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: MIME_TYPES[exportType],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        link.setAttribute(
          "download",
          `${
            dashboardInfo?.title +
            " " +
            new Date().toISOString().slice(0, 10) +
            "_" +
            getFormattedTimestamp()
          }.${exportType === "excel" ? "xlsx" : "csv"}`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("There was an error downloading the file:", error);
      })
      .finally(() => {
        document.body.style.cursor = "default";
      });
  };

  const exportOptions = [
    {
      label: "Export as Excel",
      onClick: () => {
        handleExport("excel");
      },
    },
    {
      label: "Export as CSV",
      onClick: () => {
        handleExport("csv");
      },
    },
  ];

  return (
    <Box px={3} sx={{ flexGrow: 1 }}>
      {modalData.isOpen && (
        <OverlayComponent
          isOpen={modalData.isOpen}
          handleClose={closeModal}
          data={modalData}
          dashboardId={dashboardId}
          report_type={selectedFilter.selectedLevel1?.label}
          seletedReportvalue={selectedFilter.selectedLevel2?.value}
        />
      )}
      {shouldRenderActiveFilterSection(filterSummary) && (
        <Box sx={{ mb: "10px" }}>
          <ActiveFilterSection
            appliedFilter={filterSummary}
            clearAllFilters={clearAllFilters}
            clearFilter={clearFilter}
            hideDateRange={true}
          />
        </Box>
      )}
      <Box>
        {!expandedMonths.length && !hideSummary && (
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            mt={"6px"}
          >
            {cardData.map((data, index) => (
              <Box
                key={index}
                p={2}
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "5px",
                }}
              >
                <Typography
                  align="left"
                  color="text.primary"
                  noWrap
                  sx={{
                    fontSize: "11px",
                    fontWeight: 500,
                    color: "#000",
                    mb: "20px",
                  }}
                >
                  {data.title}
                </Typography>
                <Typography
                  align="left"
                  color="text.primary"
                  noWrap
                  sx={{
                    fontSize:
                      !data.number && data.number !== 0 ? "32px" : "36px",
                    fontWeight: !data.number && data.number !== 0 ? 400 : 500,
                    color: "#343A40",
                    lineHeight: "normal",
                  }}
                >
                  <span style={{ color: "#71767A", marginRight: "8px" }}>
                    ₹
                  </span>
                  {!data.number && data.number !== 0
                    ? "NA"
                    : data.number.toLocaleString("en-IN")}
                </Typography>
              </Box>
            ))}
          </Stack>
        )}
        <Stack
          direction="row"
          my={2}
          spacing={2}
          justifyContent="space-between"
        >
          <Accordion
            expanded={isExpanded}
            sx={{
              borderRadius: "5px",
              margin: "0px !important",
              maxHeight: getAccordionMaxHeight(),
              ".MuiCollapse-root": {
                overflow: "auto",
                height: "calc(100% - 40px) !important",
              },
            }}
          >
            <AccordionSummary
              sx={{ maxHeight: "30px", minHeight: "unset !important" }}
            >
              <Box sx={{ flex: 1, display: "flex" }}></Box>
              <Box
                onClick={handleAccordionChange}
                sx={{
                  overflow: "hidden",
                  position: "absolute",
                  right: "4px",
                  top: "4px",
                  maxWidth: "24px !important",
                  height: "20px",
                  p: 0,
                }}
              >
                {keyboardTabIcon}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ flexDirection: "row", display: "flex" }}>
              {isExpanded && (
                <Box sx={boxStyles}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "18px",
                      fontWeight: 500,
                      mb: "14px",
                      color: theme.palette.text.primary,
                    }}
                  >
                    Filters
                  </Typography>

                  <MonthYearFilter
                    label="From"
                    selectedMonth={selectedFromMonth}
                    selectedYear={selectedFromYear}
                    setSelectedMonth={setSelectedFromMonth}
                    setSelectedYear={setSelectedFromYear}
                    defaultYearLabel={defaultFromYear}
                  />

                  <MonthYearFilter
                    label="To"
                    selectedMonth={selectedToMonth}
                    selectedYear={selectedToYear}
                    setSelectedMonth={setSelectedToMonth}
                    setSelectedYear={setSelectedToYear}
                    defaultYearLabel={defaultToYear}
                  />

                  {yearError && (
                    <Typography
                      variant="body2"
                      color="error"
                      align="right"
                      fontSize={"10px"}
                      sx={{ mb: 1 }}
                    >
                      {yearError}
                    </Typography>
                  )}

                  <MultiSelectDropdown
                    label={"Customer"}
                    placeholder={`${customerOptions.length} Options`}
                    options={customerOptions}
                    onSelect={handleCustomerChange}
                    selectedValues={selectedCustomers}
                    loading={fetchCustomerObj.loading}
                    disabled={false}
                  />

                  <MultiSelectDropdown
                    label={"Account Manager"}
                    placeholder={`${accountManagerOptions.length} Options`}
                    options={accountManagerOptions}
                    onSelect={handleAccountManagerChange}
                    selectedValues={selectedAccountManagers}
                    loading={fetchCustomerObj.loading}
                    disabled={false}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      mt: "10px",
                    }}
                  >
                    <Button
                      onClick={() => applyFilter(true)}
                      disabled={!enableButton || !!yearError}
                      sx={{
                        maxWidth: "180px",
                        textTransform: "none",
                        mr: "10px",
                        py: "4px",
                        px: "12px",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Apply
                    </Button>
                    <Button
                      sx={{
                        textTransform: "none",
                        color: "#343A40",
                        borderColor: "#343A40",
                        py: "4px",
                        px: "12px",
                      }}
                      onClick={() => clearAllFilters(true)}
                      disabled={!hasFiltersSelected}
                      variant="outlined"
                    >
                      Clear filters
                    </Button>
                  </Box>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>

          <TableContainer
            component={Paper}
            elevation={0}
            ref={containerRef}
            sx={{
              maxHeight: `73.5vh`,
              width: `100%`,
              boxShadow: "none",
              borderRadius: "5px",
              overflowY: "auto",
              overflowX: "auto",
              backgroundColor: "#F9F9F9",
              position: "relative",
              ...scrollbarStyle.body,
            }}
          >
            {error && error !== "" ? (
              <Typography
                sx={{
                  justifyContent: "center",
                  width: `100%`,
                  height: `100%`,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {error}
              </Typography>
            ) : monthToggle ? (
              <Table
                size="small"
                className={"table"}
                aria-label="eva table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <StickyTableHeadCell
                      sx={{ py: 0 }}
                      noBorderTop
                      noBorderBottom
                      noBorderLeft
                      textAlign="left"
                      rowSpan={2}
                      fontSize="16px"
                    >
                      Month
                    </StickyTableHeadCell>
                    {years.map((year, index) => (
                      <StyledTableHeadCell
                        key={year}
                        fontWeight={600}
                        fontSize="18px"
                        noBorderTop
                        noBorderBottom
                        colSpan={index === 0 ? 4 : 2}
                        rowSpan={1}
                        sx={{
                          py: 1,
                          lineHeight: "0px",
                          height: "14px",
                          backgroundColor:
                            index === 0
                              ? "#ECEFF3"
                              : index % 2 === 0
                              ? "#E5E5E5"
                              : "#ECECEC",
                        }}
                      >
                        {year}
                      </StyledTableHeadCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <StyledSecondRowTableHeadCell
                      fontSize="16px"
                      noBorderTop
                      noBorderBottom
                      noBorderRight
                      textAlign="right"
                      rowSpan={1}
                      sx={{ py: 0, pb: "10px" }}
                    >
                      <span>Revenue</span>
                      <span style={{ color: "#71767A" }}> (₹)</span>
                    </StyledSecondRowTableHeadCell>
                    <StyledSecondRowTableHeadCell
                      fontSize="16px"
                      noBorderTop
                      noBorderBottom
                      noBorderRight
                      textAlign="right"
                      rowSpan={1}
                      sx={{ py: 0, pb: "10px" }}
                    >
                      <span>Salary Expenses </span>
                      <span style={{ color: "#71767A" }}> (₹)</span>
                    </StyledSecondRowTableHeadCell>
                    <StyledSecondRowTableHeadCell
                      fontSize="16px"
                      noBorderTop
                      noBorderBottom
                      noBorderRight
                      textAlign="right"
                      rowSpan={1}
                      sx={{ py: 0, pb: "10px" }}
                    >
                      <span>Other Expenses </span>
                      <span style={{ color: "#71767A" }}> (₹)</span>
                    </StyledSecondRowTableHeadCell>
                    <StyledSecondRowTableHeadCell
                      fontSize="16px"
                      noBorderTop
                      noBorderBottom
                      textAlign="right"
                      rowSpan={1}
                      sx={{ py: 0, pb: "10px" }}
                    >
                      <span>Gross Profit </span>
                      <span style={{ color: "#71767A" }}> (₹)</span>
                    </StyledSecondRowTableHeadCell>
                    {years.slice(1).map((year, index) => (
                      <React.Fragment key={year}>
                        <StyledSecondRowTableHeadCell
                          fontSize="16px"
                          noBorderTop
                          noBorderBottom
                          noBorderRight
                          textAlign="right"
                          rowSpan={1}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "#ECECEC" : "#E5E5E5",
                            py: 0,
                            pb: "10px",
                          }}
                        >
                          <span>Revenue</span>
                          <span style={{ color: "#71767A" }}> (₹)</span>
                        </StyledSecondRowTableHeadCell>
                        <StyledSecondRowTableHeadCell
                          fontSize="16px"
                          noBorderTop
                          noBorderBottom
                          textAlign="right"
                          rowSpan={1}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "#ECECEC" : "#E5E5E5",
                            py: 0,
                            pb: "10px",
                          }}
                        >
                          Gross Profit (₹)
                        </StyledSecondRowTableHeadCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data ? (
                    months.map((month) => (
                      <React.Fragment key={month}>
                        <TableRow
                          sx={{
                            height: "44px",
                            ...(expandedMonths.includes(month) && {
                              position: "sticky",
                              top: "64px",
                              zIndex: 2,
                            }),
                          }}
                          ref={(el) =>
                            (monthRefs.current[month] =
                              el as HTMLTableRowElement | null)
                          }
                        >
                          <StickyTableCell
                            noBorderLeft
                            noBorderTop
                            hoverBackgroundColor="#DDE2E6"
                            onClick={() => handleCellClick(month)}
                            fontColor="#343A40"
                            component="th"
                            scope="row"
                            textAlign="left"
                            sx={{
                              width: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "150px",
                              cursor: disabledMonths.includes(month)
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {month}
                                <img
                                  src={
                                    expandedMonths.includes(month)
                                      ? LessIcon
                                      : ExpandIcon
                                  }
                                  alt="expand icon"
                                  style={{
                                    marginLeft: 8,
                                    lineHeight: "0px",
                                    filter: disabledMonths.includes(month)
                                      ? "grayscale(100%)"
                                      : "none",
                                  }}
                                />
                              </Box>
                              {expandedMonths.includes(month) && (
                                <Box
                                  sx={{
                                    fontSize: "14px",
                                    textAlign: "right",
                                    lineHeight: "0px",
                                    paddingY: "6px",
                                  }}
                                >
                                  {getSubHeading()}
                                </Box>
                              )}
                            </Box>
                          </StickyTableCell>
                          <StyledTableCell
                            noBorderTop
                            noBorderLeft
                            noBorderRight
                            textAlign="right"
                            sx={{
                              width: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "150px",
                            }}
                          >
                            {data[currentYear]?.[month]?.revenue != null
                              ? data[currentYear]?.[
                                  month
                                ]?.revenue.toLocaleString("en-IN")
                              : "NA"}
                          </StyledTableCell>
                          <StyledTableCell
                            noBorderTop
                            noBorderLeft
                            noBorderRight
                            textAlign="right"
                            sx={{
                              width: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "150px",
                            }}
                          >
                            {data[currentYear]?.[month]?.salary_expenses != null
                              ? data[currentYear]?.[
                                  month
                                ]?.salary_expenses.toLocaleString("en-IN")
                              : "NA"}
                          </StyledTableCell>
                          <StyledTableCell
                            noBorderTop
                            noBorderLeft
                            noBorderRight
                            textAlign="right"
                            sx={{
                              width: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "150px",
                            }}
                          >
                            {data[currentYear]?.[month]?.other_expenses != null
                              ? data[currentYear]?.[
                                  month
                                ]?.other_expenses.toLocaleString("en-IN")
                              : "NA"}
                          </StyledTableCell>
                          <StyledTableCell
                            noBorderTop
                            noBorderLeft
                            noBorderRight
                            textAlign="right"
                            sx={{
                              width: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "150px",
                            }}
                          >
                            {data[currentYear]?.[month]?.gross_profit != null
                              ? data[currentYear]?.[
                                  month
                                ]?.gross_profit.toLocaleString("en-IN")
                              : "NA"}
                          </StyledTableCell>
                          {years.slice(1).map((year, index) => (
                            <React.Fragment key={year}>
                              <StyledTableCell
                                noBorderTop
                                noBorderRight
                                textAlign="right"
                                sx={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#FBF8F8" : "#F0EEEE",
                                  width: "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "150px",
                                }}
                              >
                                {data[year as keyof Data]?.[month]?.revenue !=
                                null
                                  ? data[year as keyof Data]?.[
                                      month
                                    ]?.revenue.toLocaleString("en-IN")
                                  : "NA"}
                              </StyledTableCell>
                              <StyledTableCell
                                noBorderTop
                                noBorderRight
                                noBorderLeft
                                textAlign="right"
                                sx={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#FBF8F8" : "#F0EEEE",
                                  width: "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "150px",
                                }}
                              >
                                {data[year as keyof Data]?.[month]
                                  ?.gross_profit != null
                                  ? data[year as keyof Data]?.[
                                      month
                                    ]?.gross_profit.toLocaleString("en-IN")
                                  : "NA"}
                              </StyledTableCell>
                            </React.Fragment>
                          ))}
                        </TableRow>
                        {expandedMonths.includes(month) && (
                          <StyledInnerTableRow ref={currentExpandedMonth}>
                            <StyledTableCell
                              colSpan={18}
                              sx={{ p: 0 }}
                              noBorderLeft
                            >
                              <Table
                                sx={{ borderCollapse: "separate" }}
                                size="small"
                              >
                                <TableBody>
                                  {Array.isArray(expandedData[month]) &&
                                  expandedData[month].length > 0 ? (
                                    expandedData[month].map(
                                      (detail: any, index: any) => (
                                        <TableRow key={index}>
                                          <StickyTableCell
                                            className="open-overlay"
                                            fontColor={
                                              getOverlayData().canOpenOverlay
                                                ? "#2190FF"
                                                : "#343A40"
                                            }
                                            textAlign="right"
                                            noBorderTop
                                            sx={{
                                              ...style.subTableText,
                                              width: "150px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "150px",
                                              ...getOverlayData().style,
                                            }}
                                            onClick={() => {
                                              openOverlay(
                                                currentYear,
                                                detail,
                                                month,
                                                columnNameKeys.account
                                              );
                                            }}
                                          >
                                            {detail.name}
                                          </StickyTableCell>
                                          <StyledTableCell
                                            className="open-overlay"
                                            noBorderTop
                                            noBorderRight
                                            noBorderLeft
                                            textAlign="right"
                                            sx={{
                                              ...style.subTableText,
                                              ...getOverlayData().style,
                                              width: "150px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "150px",
                                            }}
                                            onClick={() => {
                                              openOverlay(
                                                currentYear,
                                                detail,
                                                month,
                                                columnNameKeys.revenue
                                              );
                                            }}
                                          >
                                            {detail[currentYear]?.revenue !=
                                            null
                                              ? detail[
                                                  currentYear
                                                ]?.revenue.toLocaleString(
                                                  "en-IN"
                                                )
                                              : "NA"}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            className="open-overlay"
                                            noBorderTop
                                            noBorderRight
                                            noBorderLeft
                                            textAlign="right"
                                            sx={{
                                              ...style.subTableText,
                                              ...getOverlayData().style,
                                              width: "150px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "150px",
                                            }}
                                            onClick={() => {
                                              openOverlay(
                                                currentYear,
                                                detail,
                                                month,
                                                columnNameKeys.salaryExpense
                                              );
                                            }}
                                          >
                                            {detail[currentYear]
                                              ?.salary_expense != null
                                              ? detail[
                                                  currentYear
                                                ]?.salary_expense.toLocaleString(
                                                  "en-IN"
                                                )
                                              : "NA"}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            className="open-overlay"
                                            textAlign="right"
                                            noBorderTop
                                            noBorderRight
                                            noBorderLeft
                                            sx={{
                                              ...style.subTableText,
                                              ...getOverlayData().style,
                                              width: "150px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "150px",
                                            }}
                                            onClick={() => {
                                              openOverlay(
                                                currentYear,
                                                detail,
                                                month,
                                                columnNameKeys.otherExpense
                                              );
                                            }}
                                          >
                                            {detail[currentYear]
                                              ?.other_expense != null
                                              ? detail[
                                                  currentYear
                                                ].other_expense.toLocaleString(
                                                  "en-IN"
                                                )
                                              : "NA"}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            textAlign="right"
                                            noBorderTop
                                            noBorderRight
                                            noBorderLeft
                                            sx={{
                                              width: "150px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "150px",
                                              ...style.subTableText,
                                            }}
                                          >
                                            {detail[currentYear]
                                              ?.gross_profit != null
                                              ? detail[
                                                  currentYear
                                                ]?.gross_profit.toLocaleString(
                                                  "en-IN"
                                                )
                                              : "NA"}
                                          </StyledTableCell>
                                          {years.slice(1).map((year, idx) => (
                                            <React.Fragment key={year}>
                                              <StyledTableCell
                                                className="open-overlay"
                                                textAlign="right"
                                                noBorderTop
                                                noBorderRight
                                                sx={{
                                                  ...style.subTableText,
                                                  ...getOverlayData().style,
                                                  width: "150px",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  maxWidth: "150px",
                                                  backgroundColor:
                                                    idx % 2 === 0
                                                      ? "#FBF8F8"
                                                      : "#F0EEEE",
                                                }}
                                                onClick={() => {
                                                  openOverlay(
                                                    year,
                                                    detail,
                                                    month,
                                                    columnNameKeys.revenue
                                                  );
                                                }}
                                              >
                                                {detail[year as keyof Data]
                                                  ?.revenue != null
                                                  ? detail[
                                                      year as keyof Data
                                                    ]?.revenue.toLocaleString(
                                                      "en-IN"
                                                    )
                                                  : "NA"}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                textAlign="right"
                                                noBorderTop
                                                noBorderRight
                                                noBorderLeft
                                                sx={{
                                                  width: "150px",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  maxWidth: "150px",
                                                  ...style.subTableText,
                                                  backgroundColor:
                                                    idx % 2 === 0
                                                      ? "#FBF8F8"
                                                      : "#F0EEEE",
                                                }}
                                              >
                                                {detail[year as keyof Data]
                                                  ?.gross_profit != null
                                                  ? detail[
                                                      year as keyof Data
                                                    ]?.gross_profit.toLocaleString(
                                                      "en-IN"
                                                    )
                                                  : "NA"}
                                              </StyledTableCell>
                                            </React.Fragment>
                                          ))}
                                        </TableRow>
                                      )
                                    )
                                  ) : (
                                    <TableRow>
                                      <StyledTableCell
                                        sx={{ overflow: "hidden" }}
                                        noBorderBottom
                                        noBorderLeft
                                        noBorderRight
                                        noBorderTop
                                        colSpan={18}
                                        align="center"
                                      >
                                        <Typography
                                          sx={{ textAlign: "center" }}
                                        >
                                          {!loading
                                            ? "No Data Available"
                                            : "Loading..."}
                                        </Typography>
                                      </StyledTableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                                <TableRow>
                                  <StyledTableCell
                                    colSpan={(years.length - 1) * 2 + 5}
                                    sx={{
                                      p: 0,
                                      backgroundColor: "#F5F5F5",
                                    }}
                                    noBorderRight
                                    noBorderLeft
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        position: "sticky",
                                        left: "0",
                                        right: 0,
                                        width: `calc(${
                                          isExpanded ? "84vw" : "95.6vw"
                                        } - 60px)`,
                                        borderLeft:
                                          "0.5px solid rgba(0, 0, 0, 0.12)",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            pl: "30px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          Show
                                        </Typography>
                                        <Select
                                          size="small"
                                          sx={{
                                            mx: "12px",
                                            height: "24px",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            p: 0,
                                            my: "6px",
                                            backgroundColor: "#FFFFFF",
                                          }}
                                          value={rowsPerPage[month]}
                                          onChange={(event) =>
                                            handleRowsPerPageChange(
                                              event,
                                              month
                                            )
                                          }
                                          displayEmpty
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          <MenuItem value={50}>50</MenuItem>
                                          <MenuItem value={100}>100</MenuItem>
                                        </Select>
                                      </Box>
                                      <Pagination
                                        count={Math.ceil(
                                          (totalCount[month] || 0) /
                                            rowsPerPage[month]
                                        )}
                                        page={currentPage[month] || 1}
                                        onChange={(event, newPage) =>
                                          handlePageChange(
                                            event,
                                            newPage,
                                            month
                                          )
                                        }
                                        variant="outlined"
                                        shape="rounded"
                                        sx={{
                                          "& .MuiPaginationItem-root": {
                                            height: "24px",
                                            color: "gray",
                                            "&.Mui-selected": {
                                              backgroundColor: "#4E4E4E",
                                              color: "white",
                                            },
                                          },
                                          "& .MuiPaginationItem-icon": {
                                            color: "#E6292E",
                                          },
                                        }}
                                      />
                                    </Box>
                                  </StyledTableCell>
                                </TableRow>
                              </Table>
                            </StyledTableCell>
                          </StyledInnerTableRow>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <StyledTableCell
                        sx={{ height: "52vh", overflow: "hidden" }}
                        noBorderBottom
                        noBorderLeft
                        noBorderRight
                        noBorderTop
                        colSpan={18}
                        align="center"
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          {!fetchFinancialOverviewObj.loading
                            ? "No Data Available"
                            : ""}
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            ) : (
              <CustomerWiseTable
                dashboard_id={dashboardId}
                applied_filters={appliedFilter}
                setLoading={setLoading}
                isExpanded={isExpanded}
                from_month={appliedFilter.fromMonth ?? ""}
                to_month={appliedFilter.toMonth ?? ""}
                from_year={appliedFilter.fromYear ?? defaultFromYear}
                to_year={appliedFilter.toYear ?? defaultToYear}
                setHideSummary={setHideSummary}
                loading={loading}
                openOverlay={openOverlay}
              />
            )}
          </TableContainer>
        </Stack>
        <Loading open={fetchFinancialOverviewObj.loading || loading} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: isEmbeddedDashboard() ? theme.spacing(2) : theme.spacing(16),
          right: theme.spacing(4),
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={4}
        >
          {dashboardInfo && monthToggle && (
            <ExportButton exportOptions={exportOptions} />
          )}
          {dashboardInfo &&
            dashboardInfo.charts &&
            dashboardInfo.charts.length > 0 && (
              <ExploreDataMenu dashboardInfo={dashboardInfo} />
            )}
        </Stack>
      </Box>
    </Box>
  );
};

function calculateTotals(data: Data) {
  const cardData: { title: string; number: number }[] = [];
  cardData.push({
    title: "Total Revenue",
    number: data?.revenue,
  });
  cardData.push({
    title: "Total Salary Expense",
    number: data?.salary_expense,
  });
  cardData.push({
    title: "Total Other Expense",
    number: data?.other_expense,
  });
  cardData.push({
    title: "Total Gross Profit",
    number: data?.gross_profit,
  });

  return cardData;
}

export default FinancialAnalysisDashboard;
