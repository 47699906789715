import React, { useCallback } from "react";
import AceEditor from "react-ace";
import AppBar from "../layouts/AppBar";
import Footer from "../layouts/Footer";
import Loading from "../components/Loading";
import axiosConfig from "../utils/axiosConfig";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import AddchartIcon from "@mui/icons-material/Addchart";
import { useLocation } from "react-router";
import { baseUrl } from "../config";
import { useData } from "../contexts/DataContext";
import { TableData, SavedQuery, QueryHistory } from "../utils/types";
import useToggle from "../hooks/useToggle";
import MultiMenu from "../components/MultiMenu";
import ColumnSelector from "../components/ColumnSelector";
import VisualizeDrawer from "../components/VisualizeDrawer";

import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { ChartBuilderProvider } from "../contexts/ChartBuilderContext";

const DEFAULT_CHART_NAME = "Untitled Query";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "5px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const extractLineNumber = (inputString: string) => {
  const lineNumberRegex = /line (\d+)/i;
  const match = lineNumberRegex.exec(inputString);
  return match ? parseInt(match[1], 10) - 1 : null;
};

interface RunError {
  line: number | null;
  message: string | null;
}

const SqlLab: React.FC = () => {
  const theme = useTheme();
  const { dashboardList } = useData();
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const dashboardId: number = parseInt(queryParams.get("dashboardId") || "");
  const chartId: number = parseInt(queryParams.get("chartId") || "");

  const isApiCallInProgress = React.useRef<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [queries, setQueries] = React.useState<SavedQuery[]>([]);
  const [currentQuery, setCurrentQuery] = React.useState<SavedQuery | null>(
    null
  );
  const [queryHistory, setQueryHistory] = React.useState<QueryHistory[]>([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [saveMenuOpen, setSaveMenuOpen] = React.useState(false);
  const [deleteMenuOpen, setDeleteMenuOpen] = React.useState(false);
  const visualizerToggle = useToggle();
  const [anchorElNewQuery, setAnchorElNewQuery] =
    React.useState<null | HTMLElement>(null);
  const isNewQueryMenuOpen = Boolean(anchorElNewQuery);
  const [saveMode, setSaveMode] = React.useState("save");
  const [saveTitle, setSaveTitle] = React.useState(DEFAULT_CHART_NAME);
  const [runError, setRunError] = React.useState<RunError>({
    line: null,
    message: null,
  });

  const handleSaveMenuOpen = () => {
    setSaveMode("save");
    if (currentQuery?.name) setSaveTitle(currentQuery.name);
    setSaveMenuOpen(true);
  };

  const handleSaveMenuClose = () => {
    setSaveMenuOpen(false);
  };

  const handleDeleteMenuOpen = () => {
    setDeleteMenuOpen(true);
  };

  const handleDeleteMenuClose = () => {
    setDeleteMenuOpen(false);
  };

  const handleNewQueryMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNewQuery(event.currentTarget);
  };

  const handleNewQueryMenuClose = () => {
    setAnchorElNewQuery(null);
  };

  const handleSaveModeChange = (e: any) => {
    setSaveMode(e.target.value);
  };

  const handleSaveTitleChange = (e: any) => {
    setSaveTitle(e.target.value);
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newtabValue: number
  ) => {
    setTabValue(newtabValue);
  };

  function onChange(newSql: string) {
    if (!currentQuery) return;
    changeQuery({ ...currentQuery, sql: newSql });
  }

  function changeQuery(newQuery: SavedQuery | null) {
    setRunError({ line: null, message: null });
    setCurrentQuery(newQuery);
  }

  const resetQueries = () => {
    setQueries((prevQueries) => {
      return prevQueries.map((_savedQuery) => ({
        ..._savedQuery,
        sql: _savedQuery.original_sql,
      }));
    });
  };

  const handleQueryResponse = (_savedQuery: SavedQuery, isSaveAs: boolean) => {
    const existIdx = queries.findIndex(
      (_query) => _query.id === _savedQuery.id
    );
    existIdx >= 0
      ? setQueries((prevQueries) => {
          prevQueries[existIdx] = _savedQuery;
          return prevQueries;
        })
      : setQueries((prevQueries) => {
          return [...prevQueries, _savedQuery];
        });
    resetQueries();
    changeQuery(_savedQuery);
  };

  const saveQuery = async () => {
    if (!currentQuery) return;
    try {
      const isSaveAs = saveMode === "save-as";
      const reqData = {
        dashboard_id: currentQuery.dashboard_id,
        chart_id: currentQuery.chart_id,
        name: saveTitle,
        sql: currentQuery.sql,
        database_id: currentQuery.database_id,
        backend: currentQuery.backend,
      };

      const response = await axiosConfig({
        method: isSaveAs ? "POST" : "PUT",
        url: `${baseUrl}/saved-query/${isSaveAs ? "" : currentQuery.id}`,
        data: reqData,
      });

      handleQueryResponse(
        {
          ...response.data,
          original_sql: response.data.sql,
        },
        isSaveAs
      );
    } catch (error) {
      console.error(error);
    } finally {
      handleSaveMenuClose();
    }
  };

  const createQuery = useCallback(
    (
      dashboard_id: number,
      chart_id: number,
      sql: string | null = null,
      database_id: number | null = null
    ) => {
      handleNewQueryMenuClose();
      if (!dashboard_id || !chart_id || isApiCallInProgress.current) return;

      const dashboardInfo = dashboardList.find(
        (_dashboard) => _dashboard.id === dashboard_id
      );

      if (!dashboardInfo) return;

      const _chartInfo = dashboardInfo.charts.find(
        (_chart) => _chart.id === chart_id
      );

      if (!_chartInfo) return;

      const run = async () => {
        try {
          setLoading(true);
          isApiCallInProgress.current = true;
          const payload: any = {
            dashboard_id,
            chart_id,
            name: _chartInfo.name || DEFAULT_CHART_NAME,
          };
          if (sql && database_id) {
            payload.sql = sql;
            payload.database_id = database_id;
          }
          const response = await axiosConfig({
            method: "POST",
            url: `${baseUrl}/saved-query/`,
            data: payload,
          });

          const newQuery: SavedQuery = {
            ...response.data,
            original_sql: response.data.sql,
          };

          setQueries((prevQueries) => {
            return [...prevQueries, newQuery];
          });
          changeQuery(newQuery);
        } catch (error) {
          console.error(error);
        } finally {
          isApiCallInProgress.current = false;
          setLoading(false);
          window.history.replaceState({}, "", "/sqllab");
        }
      };

      return run();
    },
    [dashboardList]
  );

  const restoreQuery = async (_q: QueryHistory) => {
    createQuery(_q.dashboard_id, _q.chart_id, _q.sql, _q.database_id);
  };

  const deleteQuery = async (id: number) => {
    if (!queries.find((_query) => _query.id === id)) return;

    try {
      const response = await axiosConfig({
        method: "DELETE",
        url: `${baseUrl}/saved-query/${id}`,
      });

      if (response.status !== 200)
        throw new Error("Unable to delete the query!", response.data);

      setQueries((prevQuery) => {
        const _queries = prevQuery.filter((_query) => _query.id !== id);
        if (id === currentQuery?.id) {
          changeQuery(
            _queries.sort((a, b) => a.id - b.id)[_queries.length - 1] || null
          );
        }
        return _queries;
      });
    } catch (error) {
      console.error(error);
    } finally {
      handleDeleteMenuClose();
    }
  };

  const addQueryHistory = async (_queryHistory: QueryHistory) => {
    try {
      const response = await axiosConfig({
        method: "POST",
        url: `${baseUrl}/query-history/`,
        data: _queryHistory,
      });

      if (response.status !== 200) throw new Error(response.data);

      setQueryHistory((_prevQueryHistory) => [
        ..._prevQueryHistory,
        response.data,
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const executeQuery = async () => {
    if (!currentQuery) return;
    setRunError({ line: null, message: null });
    setTabValue(0);
    setLoading(true);
    try {
      const startTime = new Date();
      const response = await axiosConfig({
        method: "POST",
        url: `${baseUrl}/run-query/`,
        data: {
          database_id: currentQuery.database_id,
          sql: currentQuery.sql,
          chart_id: currentQuery.chart_id,
          dashboard_id: currentQuery.dashboard_id,
        },
      });

      const endTime = new Date();

      if (response.status !== 200)
        throw new Error("Something went wrong!", response.data);

      addQueryHistory({
        sql: currentQuery.sql,
        database_id: currentQuery.database_id,
        chart_id: currentQuery.chart_id,
        dashboard_id: currentQuery.dashboard_id,
        started_at: startTime,
        ended_at: endTime,
        status: response.data.status || "failed",
      });

      if (!response.data.data) {
        setCurrentQuery({ ...currentQuery, data: undefined });
        setRunError({
          line: response?.data?.line_number
            ? response?.data?.line_number - 1
            : extractLineNumber(response?.data?.message),
          message: response.data.message || "Something went wrong!",
        });
        return;
      }

      const columns: any[] = [];
      const rows: any[] = [];

      response.data.data.columns.map((_col: any) =>
        columns.push({
          field: _col.name,
          headerName: _col.column_name,
          width: 300,
          type: _col.type,
        })
      );
      response.data.data.data.map((_row: any, id: number) =>
        rows.push({ id, ..._row })
      );

      const data: TableData = { columns, rows };

      changeQuery({ ...currentQuery, data });
    } catch (error: any) {
      console.error(error);
      setCurrentQuery({ ...currentQuery, data: undefined });
      setRunError({
        line: extractLineNumber(error?.response?.data?.message),
        message: error?.response?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (dashboardList.length === 0) return;

    const fetchSavedQueries = async () => {
      try {
        const response: any = await axiosConfig({
          method: "GET",
          url: `${baseUrl}/saved-query/`,
        });
        const _queries: SavedQuery[] = response.data.map(
          (data: SavedQuery) => ({ ...data, original_sql: data.sql })
        );
        setQueries(_queries);
        changeQuery(
          _queries.sort((a, b) => a.id - b.id)[_queries.length - 1] || null
        );
      } catch (error) {
        console.error(error);
      }
    };

    const fetchQueryHistory = async () => {
      try {
        const response: any = await axiosConfig({
          method: "GET",
          url: `${baseUrl}/query-history/`,
        });
        const histories: QueryHistory[] = response.data;
        setQueryHistory(histories);
      } catch (error) {
        console.error(error);
      }
    };

    const init = async () => {
      if (dashboardId && chartId) {
        await createQuery(dashboardId, chartId);
      }
      await fetchSavedQueries();
      await fetchQueryHistory();
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardList]);

  React.useEffect(() => {
    if (!currentQuery) return;
    const idx = queries.findIndex((_query) => _query.id === currentQuery.id);
    if (!idx) return;
    setQueries((prevQueries) => {
      prevQueries[idx] = currentQuery;
      return prevQueries;
    });
  }, [currentQuery, queries]);

  const renderModal = (
    <Modal
      open={saveMenuOpen}
      onClose={handleSaveMenuClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Save Query
        </Typography>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={saveMode}
            onChange={handleSaveModeChange}
          >
            <FormControlLabel
              value="save"
              control={<Radio color="primary" />}
              label="Save (Overwrite)"
            />
            <FormControlLabel
              value="save-as"
              control={<Radio color="primary" />}
              label="Save as..."
            />
          </RadioGroup>
          <Stack my={4}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Name"
              variant="outlined"
              color="primary"
              value={saveTitle}
              InputLabelProps={{ shrink: true }}
              onChange={handleSaveTitleChange}
            />
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              color="primary"
              variant="outlined"
              onClick={handleSaveMenuClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => saveQuery()}
            >
              Save
            </Button>
          </Stack>
        </FormControl>
      </Box>
    </Modal>
  );

  const renderDeleteModal = (
    <Modal
      open={deleteMenuOpen}
      onClose={handleDeleteMenuClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {currentQuery && (
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Query
            </Typography>
            <FormControl sx={{ width: "100%" }}>
              <Typography my={2}>
                Are you sure you want to delete this query
                <span style={{ fontWeight: "500" }}>
                  {` "${currentQuery.name}"`}
                </span>
                ?
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleDeleteMenuClose}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => deleteQuery(currentQuery.id)}
                >
                  Delete
                </Button>
              </Stack>
            </FormControl>
          </>
        )}
      </Box>
    </Modal>
  );

  const renderNewQueryMenu = (
    <Menu
      anchorEl={anchorElNewQuery}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={"charts-list"}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isNewQueryMenuOpen}
      onClose={handleNewQueryMenuClose}
      sx={{
        borderRadius: "4px",
        ".MuiPaper-root": {
          borderRadius: "4px",
        },
        ".MuiMenu-list": {
          p: 0,
          minWidth: "320px",
        },
      }}
    >
      {dashboardList.map((_dashboard, idx) => {
        return (
          _dashboard.charts.length > 0 && (
            <MultiMenu
              key={idx}
              list={{
                title: _dashboard.title,
                items: _dashboard.charts.map((_chart) => ({
                  ..._chart,
                  handleClick: () => createQuery(_dashboard.id, _chart.id),
                })),
              }}
            ></MultiMenu>
          )
        );
      })}
    </Menu>
  );

  const renderDataVisualizer = (
    <>
      {currentQuery?.data && visualizerToggle.open && (
        <ChartBuilderProvider
          defaultChartType={
            currentQuery.data.columns.length <= 1 ? "big-text" : "bar"
          }
        >
          <VisualizeDrawer
            open={visualizerToggle.open}
            handleClose={visualizerToggle.handleClose}
            savedQuery={currentQuery}
          />
        </ChartBuilderProvider>
      )}
    </>
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <AppBar />
      <Loading type="spinner" open={loading} />
      <Box sx={{ px: 2, flexGrow: 1 }}>
        <Stack
          direction="row"
          padding={2}
          justifyContent="space-between"
          alignItems="center"
          my={0}
          mx={0}
          p={0}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 500,
              color: theme.palette.text.primary,
            }}
          >
            SQL Lab
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={handleSaveMenuOpen}
              endIcon={<SaveRoundedIcon />}
              disabled={!currentQuery && true}
              sx={{
                width: "100px",
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={handleDeleteMenuOpen}
              endIcon={<DeleteRoundedIcon />}
              disabled={!currentQuery && true}
              sx={{
                width: "100px",
              }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={handleNewQueryMenuOpen}
              endIcon={<KeyboardArrowDownRoundedIcon />}
            >
              Explore Data
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={executeQuery}
              disabled={currentQuery?.sql ? false : true}
              sx={{
                width: "100px",
                color: "white",
              }}
            >
              Run
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Stack direction="row" spacing={2} sx={{ m: 2 }}>
        <Stack
          direction="column"
          spacing={0}
          p={0}
          pb={2}
          sx={{
            height: "100%",
            backgroundColor: "white",
            borderRadius: "5px",
          }}
        >
          <Typography
            sx={{
              my: 2,
              px: 2,
              fontSize: "16px",
              fontWeight: 500,
              color: theme.palette.text.primary,
            }}
          >
            Queries
          </Typography>
          <Box sx={{ overflow: "auto" }}>
            {queries.length === 0 && (
              <Typography
                px={2}
                noWrap
                sx={{ width: "300px", fontSize: "14px", fontWeight: 400 }}
              >
                Explore data to display queries
              </Typography>
            )}
            {queries
              .sort((a, b) => a.id - b.id)
              .map((_savedQuery) => (
                <Stack
                  key={_savedQuery.id}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() => changeQuery(_savedQuery)}
                  sx={{
                    mt: "2px",
                    px: 2,
                    height: "48px",
                    backgroundColor: theme.palette.grey[50],
                    ...(currentQuery?.id === _savedQuery.id && {
                      backgroundColor: "#e8e8e8",
                    }),
                    ":hover": {
                      backgroundColor: "#e8e8e8",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Tooltip
                    title={_savedQuery.name}
                    enterDelay={1000}
                    enterNextDelay={500}
                  >
                    <Typography
                      noWrap
                      sx={{ width: "300px", fontSize: "14px", fontWeight: 400 }}
                    >
                      {_savedQuery.name}
                    </Typography>
                  </Tooltip>
                </Stack>
              ))}
          </Box>
        </Stack>
        <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
          <Stack
            direction="row"
            spacing={2}
            style={{
              width: "100%",
              height: "39vh",
            }}
          >
            <AceEditor
              mode="mysql"
              theme="github"
              readOnly={currentQuery ? false : true}
              value={currentQuery?.sql || ""}
              onChange={onChange}
              name="sql-editor"
              editorProps={{ $blockScrolling: true }}
              annotations={[
                {
                  row: runError.line || undefined,
                  column: 0,
                  type: "error",
                  text: runError.message || "",
                },
              ]}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
              }}
              style={{
                height: "100%",
                width: "calc(100vw - 740px)",
                borderRadius: "5px",
              }}
            />
            <Box
              sx={{
                height: "100%",
                borderRadius: "5px",
                backgroundColor: theme.palette.background.default,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <ColumnSelector databaseId={currentQuery?.database_id || null} />
            </Box>
          </Stack>
          <Box
            padding={2}
            sx={{
              minHeight: "39vh",
              maxHeight: "100%",
              borderRadius: "5px",
              backgroundColor: "white",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Tabs
                textColor="primary"
                indicatorColor="primary"
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={{
                  ".MuiTab-root": {
                    borderRadius: "4px",
                    p: 0,
                  },
                }}
              >
                <Tab label="Results" />
                <Tab label="Query History" />
              </Tabs>
              {currentQuery?.data && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  endIcon={<AddchartIcon />}
                  disabled={!currentQuery && true}
                  onClick={() => visualizerToggle.handleOpen()}
                  sx={{
                    width: "200px",
                  }}
                >
                  Visualize Data
                </Button>
              )}
            </Stack>
            <Box mt={2} sx={{ width: "calc(100vw - 424px)" }}>
              {tabValue === 0 && (
                <>
                  {!currentQuery?.data && !runError.message && (
                    <Typography
                      mt={4}
                      color="text.secondary"
                      sx={{ width: "100%", textAlign: "center" }}
                    >
                      Run a query to display results
                    </Typography>
                  )}
                  {runError.message && (
                    <Alert severity="error">{runError.message}</Alert>
                  )}
                  {currentQuery?.data && !runError.message && (
                    <DataGrid
                      columns={currentQuery.data.columns || []}
                      rows={currentQuery.data.rows || []}
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={[10, 50, 100]}
                      slots={{ toolbar: CustomToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      sx={{
                        ".MuiButton-root": {
                          color: theme.palette.text.primary,
                        },
                      }}
                    />
                  )}
                </>
              )}
              {tabValue === 1 && (
                <DataGrid
                  columns={[
                    { field: "id", headerName: "id", minWidth: 50 },
                    {
                      field: "sql",
                      headerName: "SQL",
                      minWidth: 400,
                      renderCell: (params) => (
                        <Typography noWrap>{params.value}</Typography>
                      ),
                    },
                    {
                      field: "started_at",
                      headerName: "Executed At",
                      minWidth: 160,
                      type: "string",
                      valueGetter: (_,row) =>
                        new Date(row["ended_at"]).toLocaleString(),
                    },
                    {
                      field: "ended_at",
                      headerName: "Execution time",
                      minWidth: 160,
                      type: "string",
                      valueGetter: (_,row) => {
                        const et = new Date(row["ended_at"]).getTime();
                        const st = new Date(row["started_at"]).getTime();
                        const tt = new Date(et - st);
                        return `${tt.getSeconds()}.${tt.getMilliseconds()}s`;
                      },
                    },
                    {
                      field: "status",
                      headerName: "Status",
                      minWidth: 50,
                      align: "left",
                      renderCell: (params) =>
                        params.value === "success" ? (
                          <CheckCircleIcon
                            sx={{ color: theme.palette.success.main }}
                          />
                        ) : (
                          <ErrorIcon sx={{ color: theme.palette.error.main }} />
                        ),
                    },
                    {
                      field: "action",
                      headerName: "Action",
                      minWidth: 100,
                      type: "actions",
                      align: "center",
                      getActions: (params) => [
                        <IconButton
                          onClick={() => restoreQuery({ ...params.row })}
                        >
                          <AddCircleOutlineOutlinedIcon />
                        </IconButton>,
                      ],
                    },
                  ]}
                  rows={queryHistory}
                  columnVisibilityModel={{
                    id: false,
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "id", sort: "desc" }],
                    },
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 50, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  sx={{
                    ".MuiButton-root": {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
      </Stack>
      {renderModal}
      {renderDeleteModal}
      {renderNewQueryMenu}
      {renderDataVisualizer}
      <Footer />
    </>
  );
};

export default SqlLab;
