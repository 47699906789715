import style from "./styles/financialAnalysis";
export const convertMonthToComparable = (monthYear: string): string => {
  const [month] = monthYear.split(" ");
  const monthMap: { [key: string]: number } = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  return `${monthMap[month]}`;
};

export const getOverlayData = () => {
  const canOpenOverlay = true;
  return {
    canOpenOverlay,
    style: {
      ...(canOpenOverlay ? style.cursorPointer : style.cursorDefault),
      "&:hover": {
        background: canOpenOverlay ? "#DDE2E6" : "",
      },
    },
  };
};

export const shouldRenderActiveFilterSection = (
  filterSummary: any
): boolean => {
  return (
    filterSummary.Account_Manager.length > 0 ||
    filterSummary.Customer.length > 0 ||
    filterSummary.Date_Range
  );
};
