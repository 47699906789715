import React, { createContext, useContext, useState } from "react";
import { Filter } from "../components/FinancialAnalysis/types";

interface AppContextType {
  drawerOpen: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  selectedFilter: Filter;
  setSelectedFilter: React.Dispatch<React.SetStateAction<Filter>>;
  monthToggle: boolean;
  setMonthToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the app context
const AppContext = createContext<AppContextType | null>(null);

// Custom hook to access the app context
export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within an AppProvider");
  }
  return context;
};

// AppProvider component to wrap your application with
export const AppProvider = ({ children }: { children: any }) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState<Filter>({
    selectedLevel1: null,
    selectedLevel2: null,
  });
  const [monthToggle, setMonthToggle] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <AppContext.Provider
      value={{
        drawerOpen,
        handleDrawerOpen,
        handleDrawerClose,
        selectedFilter,
        setSelectedFilter,
        monthToggle,
        setMonthToggle,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
