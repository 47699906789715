import { useEffect, useMemo, useState, MouseEvent } from "react";
import {
  Menu,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import Breadcrumbs from "./Breadcrumbs";
import Section from "./Section";

import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";

import { useData } from "../contexts/DataContext";
import { useApp } from "../contexts/AppContext";

import { DashboardInfo } from "../utils/types";
import MultiLevelDropdown from "./FinancialAnalysis/components/MultiLevelDropdown";
import { Option } from "./FinancialAnalysis/types";
import { reportType } from "./FinancialAnalysis/constants";
import ToggleSwitch from "./FinancialAnalysis/components/ToggleSwitch";
import DefaultDuration from "./DefaultDuration";

export interface IDashboardHeaderProps {
  dashboardInfo: DashboardInfo | null;
  hideBreadcrumbs?: boolean;
  hideSectionMenu?: boolean;
  hidePreviousYearsMenu?: boolean;
  hideMonthWiseToggle?: boolean;
  showTimeline?: string;
}

export default function DashboardHeader({
  dashboardInfo,
  hideBreadcrumbs = false,
  hideSectionMenu = false,
  hidePreviousYearsMenu = true,
  hideMonthWiseToggle = true,
  showTimeline,
}: IDashboardHeaderProps) {
  const theme = useTheme();

  const { dashboardList } = useData();
  const { setSelectedFilter, setMonthToggle, monthToggle } = useApp();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [dashboardInfoState, setDashboardInfoState] = useState(dashboardInfo);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    string | null
  >(null);
  const [selectedLevel1, setSelectedLevel1] = useState<Option | null>(null);
  const [selectedLevel2, setSelectedLevel2] = useState<Option | null>(null);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const sectionDashboards = useMemo(
    () =>
      dashboardList.filter(({ section }) => section === dashboardInfo?.section),
    [dashboardInfo, dashboardList]
  );

  const setInitialData = () => {
    if (dashboardInfo?.id !== undefined) {
      setDashboardInfoState({
        ...dashboardInfo,
        ...{ breadcrumps_sections: [], custom_title: undefined },
      });
    }
  };

  useEffect(() => {
    handleMenuClose();
  }, [dashboardInfo]);

  useEffect(() => {
    if (dashboardInfo?.id) {
      if (
        (selectedLevel1?.value && selectedLevel2?.value) ||
        selectedLevel1?.label === reportType.overview
      ) {
        setSelectedFilter({
          selectedLevel1,
          selectedLevel2,
        });
        if (
          selectedLevel1.label &&
          selectedLevel1.label !== reportType.overview
        ) {
          if (dashboardInfo?.id !== undefined) {
            setDashboardInfoState({
              ...dashboardInfo,
              ...{
                breadcrumps_sections: [selectedLevel1.label],
                custom_title: selectedLevel2?.label || "",
              },
            });
          }
        } else {
          setInitialData();
        }
      } else {
        if (!selectedLevel1?.value && !selectedLevel2?.value) {
          setInitialData();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevel1, selectedLevel2, dashboardInfo]);

  useEffect(() => {
    setInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ px: 3 }}>
        {hideBreadcrumbs !== true && (
          <Breadcrumbs dashboardInfo={dashboardInfoState} />
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          my={0}
          mx={0}
          p={0}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            mt={1}
            mb={0}
            mx={0}
            p={0}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              {dashboardInfoState?.custom_title
                ? dashboardInfoState?.custom_title
                : dashboardInfo?.title
                ? dashboardInfo.title
                : `Loading...`}
            </Typography>

            {hideSectionMenu !== true && (
              <IconButton
                color="primary"
                aria-label="Section Dashboards"
                onClick={handleMenuOpen}
              >
                <ArrowDropDownCircleIcon />
              </IconButton>
            )}

            {hideMonthWiseToggle !== true && dashboardInfo?.id && (
              <Stack marginLeft={"52px"}>
                <ToggleSwitch handleToggle={setMonthToggle} />
              </Stack>
            )}
          </Stack>
          {hidePreviousYearsMenu !== true && dashboardInfo?.id && (
            <Stack
              direction="row"
              alignItems="center"
              mr={monthToggle ? 38 : 24}
            >
              <Stack
                direction="row"
                alignItems="center"
                marginRight={monthToggle ? "34px" : ""}
                marginLeft={"44px"}
              >
                <Typography
                  color={"#343A40"}
                  marginRight={"8px"}
                  fontWeight={400}
                  fontSize={"12px"}
                >
                  Type of Report
                </Typography>
                <MultiLevelDropdown
                  placeholder="Overview"
                  selectedValue={selectedDropdownValue}
                  setSelectedValue={setSelectedDropdownValue}
                  error={null}
                  selectedLevel1={selectedLevel1}
                  setSelectedLevel1={setSelectedLevel1}
                  setSelectedLevel2={setSelectedLevel2}
                  dashboardId={dashboardInfo?.id}
                />
              </Stack>
            </Stack>
          )}
          {showTimeline && <DefaultDuration duration={showTimeline} />}
        </Stack>
      </Box>

      {hideSectionMenu !== true && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          id={"dashboards-list"}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          sx={{
            borderRadius: "4px",
            ".MuiPaper-root": {
              width: "330px",
              borderRadius: "4px",
            },
            ".MuiMenu-list": {
              m: 0,
              p: 0,
              borderRadius: "4px",
            },
          }}
        >
          {dashboardInfo && (
            <Section
              dashboards={sectionDashboards}
              section={dashboardInfo.section}
            />
          )}
        </Menu>
      )}
    </>
  );
}
