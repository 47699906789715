import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/index.css";

import { ThemeProvider } from "@mui/material/styles";
import { RouterProvider } from "react-router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleUserAuthProvider } from "./contexts/GoogleAuthContext";
import { AppProvider } from "./contexts/AppContext";
import { googleClientId } from "./config";

import theme from "./utils/theme";
import router from "./utils/router";
import GlobalScrollbarStyles from "./components/GlobalScrollbarStyles";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <AppProvider>
        <GoogleUserAuthProvider>
          <ThemeProvider theme={theme}>
            <GlobalScrollbarStyles />
            <RouterProvider router={router} />
          </ThemeProvider>
        </GoogleUserAuthProvider>
      </AppProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example:  reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
