import React from "react";
import { Box, Typography } from "@mui/material";

import DashboardPanel from "./DashboardPanel";
import CustomDashboard from "./CustomDashboard";
import Embeded from "./Embeded";

import { customDashboards } from "../dashboards";

import { DashboardInfo } from "../utils/types";
import DashboardHeader from "./DashboardHeader";
import { DashboardFilterProvider } from "../contexts/DashboardFilterContext";

interface PrimaryDashboardProps {
  dashboardInfo: DashboardInfo;
}

export default function PrimaryDashboard({
  dashboardInfo,
}: PrimaryDashboardProps) {
  return (
    <Box sx={{ width: "100%" }}>
      <DashboardPanel>
        <>
          {dashboardInfo && (
            <DashboardHeader
              dashboardInfo={dashboardInfo}
              hideBreadcrumbs
              hideSectionMenu
              showTimeline={customDashboards[dashboardInfo.id].showTimeline}
            />
          )}
          {customDashboards[dashboardInfo.id] ? (
            <DashboardFilterProvider
              filters={customDashboards[dashboardInfo.id].filters}
            >
              <CustomDashboard id={dashboardInfo.id} hideTitle hideFilter />
            </DashboardFilterProvider>
          ) : dashboardInfo?.dashboard_uuid ? (
            <Embeded dashboardInfo={dashboardInfo} />
          ) : (
            <Typography></Typography>
          )}
        </>
      </DashboardPanel>
    </Box>
  );
}
